import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const MuzeumWsiStojadlaPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Muzeum wsi Stojadła" />
      <ReturnToPrevPage to={'/gminy/minsk-mazowiecki'}>
        Materiały dotyczące miasta Mińsk Mazowiecki oraz otaczającej go gminy
      </ReturnToPrevPage>
      <Header1>Muzeum wsi Stojadła</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <VideoPlayer videoId={896585925} />
        <p>
          Założone przez Zbigniewa Drewicza Muzeum Wsi w Stojadłach stanowi
          niezwykłą placówkę. Wśród eksponatów, które można w niej obejrzeć
          znajdują się różne przedmioty. Zostały one zarówno przekazane przez
          mieszkańców jak i wykonane przez właściciela obiektu. Zaprezentowane
          fotografie, makiety, obrazy i rzeźby, a także elementy wyposażenia
          gospodarstw domowych świadczą o bogatej w wydarzenia historii tej
          podmińskiej miejscowości. Jej przeszłość obrazuje między innymi
          makieta pokazująca jak wyglądały Stojadła w 1955 roku. Pieczołowicie
          przygotowana praca zawiera elementy, które przypominają o istnieniu we
          wsi np. starego budynku szkoły, dawnej siedziby ochotniczej straży
          pożarnej, stawu czy dworu. Ostatni z wymienionych obiektów został
          zwizualizowany za pomocą kolejnej z muzealnych makiet. - Dziś mało kto
          pamięta, że dwór w ogóle istniał. Natomiast jego historia jest długa i
          niezwykle ciekawa.
        </p>
        <p>
          Wielu mieszkańców znajdowało zatrudnienie na dworskim gospodarstwie,
          niektórzy z nich w ramach wynagrodzenia otrzymali część posiadłości –
          czytamy w informacji dotyczącej tej prezentacji. Warto podkreślić, że
          ostatnie zabudowania dworu uległy likwidacji w 1962 roku. Jego model
          został odtworzony z pamięci przez Zbigniewa Drewicza. Wobec tego, że
          urodził się on w 1949 roku miał niejednokrotnie szansę widzieć te
          budynki na żywo. Wskażmy, że historia stojedzkiego dworu została
          zamieszczona w dziewiętnastym tomie „Rocznika Mińskomazowieckiego”.
          Odwiedzając muzeum można ponadto obejrzeć wiele innych ciekawych
          przedmiotów.
        </p>
        <p>
          Wymieńmy w tym gronie takie eksponaty jak np. pochodzące z miejscowej
          fabryki wyrobów metalowych marka narzędziowa i forma do łyżek oraz
          kolekcje sztućców wyprodukowanych we wspomnianym zakładzie pracy. Jego
          wygląd został przedstawiony na kolejnej z makiet udostępnionych do
          oglądania we wnętrzu placówki. Zgromadzono w niej ponadto m.in. liczne
          figurki przedstawiające mieszkańców ubranych w stroje ludowe, modele
          kościoła p.w. Narodzenia Najświętszej Maryi Panny i Pałacu
          Dernałowiczów w Mińsku Mazowieckim, obrazy i inne wizerunki
          przedstawiające Jezusa Chrystusa, Matkę Boską i osoby uznane za
          święte, trofea myśliwskie, szopkę bożonarodzeniową, kołowrotek tkacki,
          instrumenty muzyczne i zabawki wykonane z drewna. Warto podkreślić, że
          utworzenie muzeum spowodowało skupienie wokół niego grupy mieszkańców
          zainteresowanych organizowaniem działań prezentujących historię wsi. W
          związku z tym pokusili się oni o powołanie do życia stowarzyszenia
          „Stojadła wczoraj i dziś”.
        </p>
        <p>
          Do najważniejszych celów jego funkcjonowania można zaliczyć: regularne
          zbieranie i archiwizację zdjęć, dokumentów, map, przedmiotów
          związanych z historią Stojadeł, aktywizację wszystkich pokoleń w celu
          utrwalania pamięci o Stojadłach w felietonach, esejach, wspomnieniach
          , wywiadach i filmach, promowanie lokalnych artystów,
          rozpowszechnianie historii Stojadeł za pomocą różnorodnych środków
          przekazu oraz integrację mieszkańców połączona z edukacją młodego
          pokolenia. Jednym z przejawów działalności stowarzyszenia było
          zorganizowanie Dnia Otwartego Muzeum Wsi w Stojadłach oraz
          zaprezentowanie placówki w ramach ogólnopolskiej Nocy Muzeów.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/minsk-mazowiecki/muzeum-wsi-stojadla/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: {
        relativePath: { regex: "/minsk-mazowiecki/muzeum-wsi-stojadla/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default MuzeumWsiStojadlaPage
